/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@stripe/stripe-js"
// https://dev.to/beezfedia/using-the-new-stripe-checkout-in-gatsby-aws-amplify-redirect-to-checkout-client-side-47jp
// https://stripe.com/docs/billing/subscriptions/checkout

import { setUser } from "./src/utils/auth"

import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import awsExports from "./src/aws-exports"
import awsExportsBackground from "./src/aws-exports-from-background"
Amplify.configure({
  ...awsExports,
  ...awsExportsBackground,
})
Auth.configure({
  ...awsExports,
  ...awsExportsBackground,
})

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
    })
    .catch(err => {
      window.localStorage.setItem("gatsbyUser", null)
    })
}
