import { Hub } from "@aws-amplify/core"
import amplitude from "./amplitude"

const isBrowser = typeof window !== `undefined`

const hubListeners = {
  signUp: {},
  signIn: {},
}

Hub.listen("auth", data => {
  if (data.payload.event === "signUp") {
    const user = data.payload.data
    const userInfo = {
      ...user.attributes,
      username: user.username,
    }
    setUser(userInfo)
    amplitude.setUserId(user.username)

    for (let id in hubListeners.signUp) {
      const callback = hubListeners.signUp[id]
      if (callback) {
        callback(user)
      }
    }
  } else if (data.payload.event === "signIn") {
    const user = data.payload.data
    const userInfo = {
      ...user.attributes,
      username: user.username,
    }
    setUser(userInfo)
    amplitude.setUserId(user.username)

    for (let id in hubListeners.signIn) {
      const callback = hubListeners.signIn[id]
      if (callback) {
        callback(user)
      }
    }
  }
})

export const addCallback = (callbackType, callback) => {
  const nextIdx = Object.keys(hubListeners[callbackType]).length
  hubListeners[callbackType][nextIdx] = callback
  return nextIdx
}

export const removeCallback = (callbackType, id) => {
  if (id) {
    hubListeners[callbackType][id] = null
  }
}

export const setUser = user =>
  (window.localStorage.gatsbyUser = JSON.stringify(user))

const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    let user = JSON.parse(window.localStorage.gatsbyUser)
    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return
  setUser({})
  amplitude.setUserId(null)
  callback()
}
