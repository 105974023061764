import * as Amplitude from "@amplitude/node"
import { v4 as uuidv4 } from "uuid"

const client = Amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY) // TOOD in prod (netlify) need to use project for live data (not dev)
var user_id = null
const default_user_id = `guest_${uuidv4()}`

const wrapper = {
  setUserId: id => {
    user_id = id
  },
  logEvent: event_type => {
    const event = {
      event_type: event_type,
    }
    if (user_id) {
      event.user_id = user_id
    } else {
      event.user_id = default_user_id
    }
    client.logEvent(event)

    // // Send any events that are currently queued for sending.
    // // Will automatically happen on the next event loop.
    // client.flush()
  },
}

export default wrapper
