/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cf4a7a7d-53d1-48a7-8b44-cd7bd5516813",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_O5PXSObNe",
    "aws_user_pools_web_client_id": "6ln6jk5rts692nhbka1t3gj398",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "stripeApi",
            "endpoint": "https://4k2nza2zi6.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
